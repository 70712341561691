// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$argon-dashboard-angular-primary: mat.define-palette(mat.$indigo-palette);
$argon-dashboard-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$argon-dashboard-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$argon-dashboard-angular-theme: mat.define-light-theme((
  color: (
    primary: $argon-dashboard-angular-primary,
    accent: $argon-dashboard-angular-accent,
    warn: $argon-dashboard-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($argon-dashboard-angular-theme);

/* You can add global styles to this file, and also import other style files */

@import '~ngx-toastr/toastr.css'; 

$O-color: #D01E22;
$N-color: #530208;

html, body { height: 100%; font-family: 'Lora', serif; }
body { margin: 0; font-family: 'Lora', serif;}

.img-icon{
  width: 45px;
  height: 45px;
}

.o-color{
  color: $O-color;
}
.n-color{
  color: $N-color;
}
.n-color-head{
  color: $N-color;
  font-weight:bolder;
}
.o-color-bg{
  background-color: $O-color;
  color: white;
}
.n-color-bg{
  background-color: $N-color;
  color: #ffffff;
}
.o-bg-color{
  background-color: $O-color;
  color: white;
}
.n-bg-color{
  background-color: $N-color;
  color: white;
}
.header{
  background-image: url('assets/img/brand/bg/bg4.jpg');
  background-size: 100% 100%;
}

.modal-body{
  background-image: url('assets/img/brand/bg/bg1.jpg');
  background-size: 100% 100%;
}
.title-header{
    font-family: 'Pattaya', sans-serif;
    text-align: center;
    font-size: 25px;
    color: $O-color;
  }

  /* Scrollbar Section */
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D01E22; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #530208; 
  }

 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
